import { Component } from 'preact';
import style from './style';

export default class Services extends Component {
    constructor() {
        super();
        this.state = { imgLoadedCount: 0 };
    }

    componentDidUpdate() {
        if (this.state.imgLoadedCount === 0) {
            var lastIMG = document.getElementsByName("lastIMG").item(0);
            if (lastIMG && lastIMG.complete) {
                this.props.computeLines();
            }
        } else if (this.state.imgLoadedCount == 4) {
            this.props.computeLines();
        }
    }

    imgLoaded = () => {
        this.setState({ imgLoadedCount: this.state.imgLoadedCount + 1 });
    }

    render() {
        return(
            <contentcontainer>
                <contentheader>
                    <h1>Services</h1>
                    <h2>hosting privacy-conscious alternatives to common services</h2>
                </contentheader>
                <contentmain>
                    <p class={this.props.nclass}>
                        What follows are many of
                        the services I self-host. I haven broken them up into 3 categories: 
                        private – services accessible only on my internal network, shared –
                        services accessible outside my network but  accessed controlled to a
                        select few, and public – services accessible on the internet. There
                        is virtually an unlimited number of things to self-host and many
                        options for each role they play. These are the ones I have decided
                        on.
                    </p>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        PRIVATE
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://www.home-assistant.io/">Home Assistant</a> is
                        the quintessential open source home automation tool. I primarily
                        use it to schedule smart lights. My use of Home Assistant is ever
                        evolving. I imagine it will be a central part of my homelab for the a
                        long while.
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/ha_tile.png" />
                    </imgcontainer>
                    <p class={this.props.nclass}>
                        <a href="https://bitwarden.com/">Bitwarden</a> is
                        an open source password manager. There is a great Android app and
                        good Firefox extension. I self-host the <a
                        href="https://github.com/dani-garcia/bitwarden_rs">Bitwarden
                        Rust Server</a> as it seems more light-weight and easier to
                        setup.
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://github.com/rmountjoy92/DashMachine">Dashmachine</a> is
                        a light-weight bookmark dashboard. I use it to bookmark all my
                        self-hosted services.
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/dm.png" />
                    </imgcontainer>
                    <p class={this.props.nclass}>
                        Sometimes I need to access these services when I’m not a home.
                        I use <a href="https://www.wireguard.com/">Wireguard</a> to
                        create a VPN allowing me access to my private services on my
                        Android or laptop when not at home. Wireguard is fast, easy to setup,
                        and pretty light-weight.
                    </p>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        SHARED
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://nextcloud.com/">Nextcloud</a> is
                        a popular open source productivity platform. This is the
                        heavy-lifter in taking control of my data. I use it for calendar,
                        contacts, tasks, file sync, document editing, and collaborating with
                        others. Many open source tools / apps have some support for using
                        Nextcloud as cloud storage. Getting Nextcloud stood up, goes a long
                        way in starting a self-hosting journey.
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://matrix.org/">Matrix</a> is
                        an open network for secure, decentralized communication, and <a
                        href="https://element.io/">Element</a> is
                        an open source chat app that uses the Matrix standard and supports
                        end-to-end encryption. I self-host a Matrix server, <a
                        href="https://github.com/matrix-org/synapse">Synapse</a>, as
                        well as an Element frontend. My like-minded friends and I use this
                        as our primary text communication medium.
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://jellyfin.org/">Jellyfin</a> is
                        an open source media solution, like Plex. I primarily use this at
                        home to stream TV,  movies, and music. There are Android and Android
                        TV apps. The Android TV app is a little rough but gets the job done.
                    </p>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        PUBLIC
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://github.com/LycheeOrg/Lychee">Lychee</a> is
                        an open source photo management system. I have not yet fully moved
                        to Lychee for sharing my photos. It will probably be a while before
                        fully migrated. And, I my test other photo management systems along
                        the way – that’s the fun of self-hosting.
                    </p>
                    <p class={this.props.nclass}>
                        <a href="https://pssge.com">pssge.com</a> is
                        a fun link-shortner that provides a landing page indicating what
                        type of content is behind the link. A friend and I developed this in
                        Angular with a ASP.NET Core backend as a learning project.
                    </p>
                    <imgcontainer>
                    <img class={[style.smallimage, style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/pssge.png" />
                    </imgcontainer>
                    <p class={this.props.nclass}>
                        And, of course, I am self-hosting this website. I built this with <a
                        href="https://preactjs.com/">Preact</a>, an
                        alternative to React. As primarily a C++ developer, creating this
                        website has been a real learning experience. Getting the layout,
                        behavior, and theming just right to mimic Visual Studio Code,
                        especially the dynamic line numbers, has been a challenge. But, it
                        was fun, and I’m pleased with the result.
                    </p>
                    <imgcontainer>
                    <img class={[style.largeimage, style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/vscode.png" name="lastIMG" />
                    </imgcontainer>
                </contentmain>
                <columnbreak/>
            </contentcontainer>
        );
    }
}