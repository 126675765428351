import { Component } from 'preact';
import style from './style';

export default class Travel extends Component {
    constructor() {
        super();
        this.state = { imgLoadedCount: 0 };
    }

    componentDidUpdate() {
        if (this.state.imgLoadedCount === 0) {
            var lastIMG = document.getElementsByName("lastIMG").item(0);
            if (lastIMG && lastIMG.complete) {
                this.props.computeLines();
            }
        } else if (this.state.imgLoadedCount == 8) {
            this.props.computeLines();
        }
    }

    imgLoaded = () => {
        this.setState({ imgLoadedCount: this.state.imgLoadedCount + 1 });
    }

    render() {
        return (
            <contentcontainer>
                <contentheader>
                    <h1>Travel Photography</h1>
                    <h2>learning photography on the go</h2>
                </contentheader>
                <contentmain>
                    <p class={this.props.nclass}>
                        I’ve always enjoyed going to new places and experiencing the culture and history.
                        Luckily, I get to does this fairly often. Some of my favorite places I’ve been are Inari, Finland; Fussen, Germany; and Kyoto, Japan.
                        As I travelled more, I took up photography as somewhat of a hobby.
                        Sadly, I have no photos or quite poor ones from most of my earlier travels (including not taking photos underneath the northern lights while in the arctic circle).
                    </p>

                    <p class={this.props.nclass}>
                        I first started with a Panasonic point-and-shoot (DMC-ZS20) with poor results. Here are some of my favorite pictures I took with it:
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/dmc1.jpg" />
                    </imgcontainer>
                    <br />
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/dmc2.jpg" />
                    </imgcontainer>
                    <br />
                    
                    <p class={this.props.nclass}>
                        After realizing I needed to get something I could control with greater detail, I upgraded to a Nikon D3300 with the default lens (AF-S DX NIKKOR 18-55mm f/3.5-5.6G VR II)
                        at the recommendation of friends and <a href="https://kenrockwell.com/tech/recommended-cameras.htm">Ken Rockwell</a>.
                        Here are some of my favorite pictures I took early on with it while learning about shutter speed and aperture size:
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/nikon1.jpg" />
                    </imgcontainer>
                    <br />
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/nikon2.jpg" />
                    </imgcontainer>
                    <br />

                    <p class={this.props.nclass}>
                        With a better grasp of the basics, I started to explore editing.
                        I learned the basics of <a href="https://rawtherapee.com">RawTherapee</a> and <a href="http://hugin.sourceforge.net">Hugin</a> (for stitching).
                        Here are some of my favorites that I edited:
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/nikon_edited1.jpg" />
                    </imgcontainer>
                    <br />
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/nikon_edited2.jpg" />
                    </imgcontainer>
                    <br />

                    <p class={this.props.nclass}>
                        I have read some about composition and this is where I probably need to put in some work.
                        I usually follow the rule of thirds, but I would like to branch out and intentionally try some other composition techniques.
                        Here are some of my favorite photos where I had composition in mind: 
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/nikon_composition1.jpg" />
                    </imgcontainer>
                    <br />
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/nikon_composition2.jpg" name="lastIMG" />
                    </imgcontainer>
                    <br />
                </contentmain>
                <columnbreak/>
            </contentcontainer>
        );
    }
}
