import { Component } from 'preact';
import style from './style';

export default class Monitoring extends Component {
    constructor() {
        super();
        this.state = { imgLoadedCount: 0 };
    }

    componentDidUpdate() {
        if (this.state.imgLoadedCount === 0) {
            var lastIMG = document.getElementsByName("lastIMG").item(0);
            if (lastIMG && lastIMG.complete) {
                this.props.computeLines();
            }
        } else if (this.state.imgLoadedCount == 3) {
            this.props.computeLines();
        }
    }

    imgLoaded = () => {
        this.setState({ imgLoadedCount: this.state.imgLoadedCount + 1 });
    }

    render() {
        return(
            <contentcontainer>
                <contentheader>
                    <h1>Monitoring</h1>
                    <h2>watching for problems automatically</h2>
                </contentheader>
                <contentmain>
                    <p class={this.props.nclass}>
                        The goal is hands-off
                        monitoring. I want to receive a notification when something goes
                        wrong. I chose to use Slack for notifications as it has many
                        integrations with other services, has an Android app, and  is
                        external to my system. I could have used a self-hosted solution,
                        like <a href="https://matrix.org/">Matrix</a>, but then I
                        would need to have some other path for notifications for when my
                        self-hosted stack experiences an outage – just simpler to use an
                        external service like Slack.
                    </p>
                    <p class={this.props.nclass}>
                        In general, monitoring needs to take place external to the thing being
                        monitored. I use a VPS for monitoring my central and backup servers.
                    </p>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        SILENT ERRORS
                    </p>
                    <p class={this.props.nclass}>
                        Is the absence of a notification an indication that everything is
                        running well? What happens when the monitoring itself fails? Who
                        watches the watchers?
                    </p>
                    <p class={this.props.nclass}>
                        I use <a
                        href="https://docs.linuxserver.io/images/docker-healthchecks">Healthchecks</a> to
                        detect these silent errors. I use a self-hosted version on my VPS
                        for monitoring on my central and backup servers and the public
                        version, <a href="https://healthchecks.io/">Healthchecks.io</a>,
                        for monitoring my VPS. Both of them are configured to send
                        notifications to Slack. In this way, I will receive a notification if
                        the monitoring itself failed.
                    </p>
                    <imgcontainer>
                    <img class={[style.smallimage, style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/hc.png" />
                    </imgcontainer>
                    <p class={this.props.nclass}>
                        My backup solution, <a
                        href="https://github.com/wkelton/abackup">abackup</a> interacts
                        with Healthchecks, both self-hosted and public, and Slack.
                        I can rest assured that my backups have occurred on schedule.
                    </p>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        UPTIME MONITORING
                    </p>
                    <p class={this.props.nclass}>
                        For uptime monitoring, I
                        run <a href="https://statping.com/">Statping</a> on
                        my VPS for realtime monitoring of my central and backup server and
                        many of the services I host. It supports external notification
                        integrations like Slack, and displays uptime information in useful
                        graphs.
                    </p>
                    <imgcontainer>
                    <img class={[style.smallimage, style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/sp.png" />
                    </imgcontainer>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        SOFTWARE UPDATES
                    </p>
                    <p class={this.props.nclass}>
                        It’s important to keep
                        your software up-to-date for security reasons as well as simply
                        having the latest and greatest. I use <a
                        href="https://crazymax.dev/diun/">Diun</a> to
                        monitor for updates for the Docker images used by the containers I
                        host. Importantly for me, Diun supports both Slack and Healthchecks. 
                    </p>
                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        USAGE DASHBOARD
                    </p>
                    <p class={this.props.nclass}>
                        I use <a
                        href="https://github.com/stefanprodan/dockprom">dockprom</a> to
                        monitor my central server and Docker containers. I have it
                        configured to alert me through Slack of high memory or CPU load on my
                        main server. dockprom produces some great graphs with Grafana to
                        inspect CPU, memory, and network usage per container.
                    </p>
                    <imgcontainer>
                    <img class={[style.imgmargin, this.props.nclass].join(" ")} onLoad={this.imgLoaded} src="/assets/images/dockprom.png" name="lastIMG" />
                    </imgcontainer>
                </contentmain>
                <columnbreak/>
            </contentcontainer>
        );
    }
}