import { Component } from 'preact';
import style from './style';

export default class ModernCpp extends Component {
    componentDidUpdate() {
        this.props.computeLines();
    }

    render() {
        return (
            <contentcontainer>
                <contentheader>
                    <h1>Modern C++</h1>
                    <h2>moving from 03 to 11+</h2>
                </contentheader>
                <contentmain>
                    <p class={this.props.nclass}>
                        During the first four years of my C++ development experience, I was primarily working on projects restricted to C++98/03.
                        Thankfully I moved on to a project that was transitioning to modern C++.
                        I picked up a copy of Scott Meyer’s <a href="https://www.amazon.com/Effective-Modern-Specific-Ways-Improve/dp/1491903996">Effective Modern C++</a> to help learn the new ways.
                        After my first read through, I focused on applying the following:
                    </p>
                    <ul class={this.props.nclass}>
                        <li><cpptag>auto</cpptag> for local variable declarations, especially when the type is readily inferred</li>
                        <li><cpptag>nullptr</cpptag> instead of NULL or worse, 0</li>
                        <li><cpptag>override</cpptag> for avoiding maintenance headaches when changing bases class virtual functions</li>
                        <li><a href="https://en.cppreference.com/w/cpp/language/type_alias">alias declarations</a> (<cpptag>using</cpptag>) instead of typedefs for readability and templating</li>
                        <li><a href="https://en.cppreference.com/w/cpp/language/enum">scoped enums</a> (<cpptag>enum class</cpptag>) for eliminating implicit casts and forward-declarations</li>
                        <li><a href="https://en.cppreference.com/w/cpp/language/function#Deleted_functions">deleted special functions</a> (<cpptag>= delete</cpptag>) for clearer intent</li>
                        <li>lambdas for quick and easy code encapsulation/reuse within a scope</li>
                        <li>smart pointers for clear ownership, exception safety, and encapsulation of destruction</li>
                        <li><cpptag>make_shared</cpptag>, <cpptag>make_unique</cpptag> to avoid using ‘new’ when possible</li>
                        <li>move semantics to increase use of value semantics by replacing copies</li>
                        <li><a href="https://en.cppreference.com/w/cpp/language/rule_of_three">rule of 5 (or 0)</a> to ensure move semantics are present for custom types</li>
                        <li>emplacement for in-place construction of objects in a container</li>
                    </ul>

                    <p class={this.props.nclass}>
                        With around two years of applying the above concepts, I had the opportunity to attend <a href="https://cppcon.org">CppCon 2017</a> in Bellevue, WA.
                        I was interested to hear what the C++ community has been up to and find ideas for helping the project I was on modernize.
                        Some C++ centric talks I attended were:
                    </p>
                    <ul class={this.props.nclass}>
                        <li><a href="https://www.youtube.com/watch?v=fI2xiUqqH3Q">C++17 Features</a><comment>//</comment><author>Bryce Adelstein</author></li>
                            <ul><li>
                                Some interesting things in 17: <a href="https://en.cppreference.com/w/cpp/language/if">selection initialization</a>, <a href="https://en.cppreference.com/w/cpp/language/class_template_argument_deduction">class template deduction</a>,
                                optional, variant, filesystem, and <a href="https://en.cppreference.com/w/cpp/container/unordered_map/insert_or_assign">insert_or_assign</a>
                            </li></ul>
                        <li><a href="https://www.youtube.com/watch?v=LmiDF2YheAM">Enhanced Value Semantics in C++</a><comment>//</comment><author>Michael Park</author></li>
                            <ul><li>
                                <a href="https://en.cppreference.com/w/cpp/language/value_category">Value semantics</a> are prefered for local reasoning and easier memory management;
                                use optional, variant, and templates to use more value semantics; const optional reference parameters can cause copies; visitors to variant need to support empty state
                            </li></ul>
                        <li><a href="https://www.youtube.com/watch?v=ybaE9qlhHvw">A Soupçon of SFINAE</a><comment>//</comment><author>Arthur O’Dwyer</author></li>
                            <ul><li>Write custom traits like is_static_castable, use prioritized tag dispatch, note that compilers try to resolve template arguments at declaration</li></ul>
                        <li><a href="https://www.youtube.com/watch?v=h8UoYG4dvH8">Practical Techniques for Improving C++ Build Times</a><comment>//</comment><author>Dmitry Panin</author></li>
                            <ul><li>
                                Decrease build times by splitting large files, declare explicit template instantiation, use forward declarations, find hidden dependencies, use
                                the <a href="https://en.cppreference.com/w/cpp/language/pimpl">pimpl idiom</a>
                            </li></ul>
                    </ul>

                    <p class={this.props.nclass}>
                        I also went to a few non-C++ language talks that were quite relevant for me:
                    </p>
                    <ul class={this.props.nclass}>
                        <li><a href="https://www.youtube.com/watch?v=ztrnb-bVVPo">Modern CMake for Modular Design</a><comment>//</comment><author>Mathieu Ropert</author></li>
                            <ul><li>Use public/private scoping when adding includes, links, definitions, etc; use side-effect free “target_” commands</li></ul>
                        <li><a href="https://www.youtube.com/watch?v=30r_SsOjg2E">Tools from the C++ Eco-System</a><comment>//</comment><author>Anastasia Kazakova</author></li>
                            <ul><li>clang-format, clang-analyze, clang-tidy, and Cppcheck</li></ul>
                        <li><a href="https://www.youtube.com/watch?v=B2XtqVZcSdM">So, You Inherited a Large Code Base</a><comment>//</comment><author>David Sankel</author></li>
                            <ul><li>Add no more tech debt by instituting coding standards and continuous integration, using clang-format, and surrounding changes with tests</li></ul>
                    </ul>

                    <p class={this.props.nclass}>
                        While there I also took the Standard Library from Scratch course by Arthur O’Dwyer where we implemented paired-down versions of common functionality from the standard library
                        like <cpptag>shared_ptr</cpptag>, <cpptag>function</cpptag>, <cpptag>any</cpptag>, <cpptag>future</cpptag>, <cpptag>find</cpptag>, <cpptag>count</cpptag>, and <cpptag>hash</cpptag>.
                        This exercised many important concepts like type erasure, small object optimization, and custom allocators.
                    </p>
                    <p class={this.props.nclass}>
                        Even though I had a good grasp on what I had learned, the conference left me with a feeling of impostor syndome so I decided to give Effective Modern C++ another read. This time through I had these take-aways: 
                    </p>
                    <ul class={this.props.nclass}>
                        <li>braced initialization to avoid narrowing conversions</li>
                        <li>lambda init captures to name captured variables and allow moving captured objects</li>
                        <li>"sink" parameters for less overloading of functions when parameters are copyable and cheap to move </li>
                        <li>special member function implementations in implementation file for pimpl idiom to avoid incomplete type issues</li>
                        <li>universal vs rvalue references as universal references come into play during type deduction and are not always <a href="https://en.cppreference.com/w/cpp/language/value_category">rvalue</a> references</li>
                    </ul>

                    <p class={this.props.nclass}>
                        Oh, and found something I was doing wrong:
                        don’t move from local variables on returning from a function as that prevents named return value optimization (<a href="https://en.cppreference.com/w/cpp/language/copy_elision">copy elision</a>).
                    </p>
                    <p class={this.props.nclass}>
                        Bad:
                    </p>                
                    <codeblock class={this.props.nclass}>
                        <div class="codeline">
                            <span style="color: #66d9ef">class </span><span style="color: #ffa000">Thing </span><span style="color: #f8f8f2">&#123;</span><span style="color: #75715e">...</span>
                            <span style="color: #f8f8f2">&#125;;</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #c2c2c2">std</span><span style="color: #f92672">::</span><span style="color: #a6e22e">vector</span><span style="color: #f92672">&lt;</span>
                            <span style="color: #ffa000">Thing</span><span style="color: #f92672">&gt; </span><span style="color: #ffffff">buildThings() </span><span style="color: #f8f8f2">&#123;</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #c2c2c2">   std</span><span style="color: #f92672">::</span><span style="color: #a6e22e">vector</span><span style="color: #f92672">&lt;</span>
                            <span style="color: #ffa000">Thing</span><span style="color: #f92672">&gt; </span><span style="color: #f8f8f2">things;</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #75715e">   ...</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #75715e">   // This prevents NRVO!</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #66d9ef">   return </span><span style="color: #c2c2c2">std</span><span style="color: #f92672">::</span><span style="color: #a6e22e">move</span><span style="color: #f8f8f2">(things); </span>
                        </div>
                        <div class="codeline">
                            <span style="color: #f8f8f2">&#125;</span>
                        </div>
                    </codeblock>
                    <p class={this.props.nclass}>
                        Good:
                    </p>           
                    <codeblock class={this.props.nclass}>
                        <div class="codeline">
                            <span style="color: #66d9ef">class </span><span style="color: #ffa000">Thing </span><span style="color: #f8f8f2">&#123;</span><span style="color: #75715e">...</span>
                            <span style="color: #f8f8f2">&#125;;</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #c2c2c2">std</span><span style="color: #f92672">::</span><span style="color: #a6e22e">vector</span><span style="color: #f92672">&lt;</span>
                            <span style="color: #ffa000">Thing</span><span style="color: #f92672">&gt; </span><span style="color: #ffffff">buildThings() </span><span style="color: #f8f8f2">&#123;</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #c2c2c2">   std</span><span style="color: #f92672">::</span><span style="color: #a6e22e">vector</span><span style="color: #f92672">&lt;</span>
                            <span style="color: #ffa000">Thing</span><span style="color: #f92672">&gt; </span><span style="color: #f8f8f2">things;</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #75715e">   ...</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #75715e">   // This allows NRVO!</span>{'\n'}
                        </div>
                        <div class="codeline">
                            <span style="color: #66d9ef">   return </span><span style="color: #f8f8f2">things; </span>
                        </div>
                        <div class="codeline">
                            <span style="color: #f8f8f2">&#125;</span>
                        </div>
                    </codeblock>

                    <p class={this.props.nclass}>
                        As I was nearing the end of my second read through, I traveled to Aspen, CO to attend <a href="http://cppnow.org/">CppNow 2018</a>.
                        This was a much different experience than CppCon with much more of a community feel.
                        I met a lot of developers using modern C++ in different fields and heard the challenges they face.
                    </p>
                    <ul class={this.props.nclass}>
                        <li><a href="https://www.youtube.com/watch?v=GgnKycitKyc">Words of Wisdom</a><comment>//</comment><author>Tony Van Eerd</author></li>
                            <ul><li>Look for incidental data structures, use explicit constructors, implement <cpptag>operator==</cpptag> for “value” classes, provide positive feedback during code reviews, do pair programming</li></ul>
                        <li><a href="https://www.youtube.com/watch?v=M2fKMP47slQ">You Can Do Better Than std::unordered_map</a><comment>//</comment><author>Malte Skarupke</author></li>
                            <ul><li><cpptag>unordered_map</cpptag> has bad performance; use Google’s flat_hash_map for all around better performance; implement a specialized hashmap for specific use cases</li></ul>
                        <li><a href="https://www.youtube.com/watch?v=gVGtNFg4ay0">Runtime Polymorphism: Back to the Basics</a><comment>//</comment><author>Louis Dionne</author></li>
                            <ul><li>
                                Inheritance forces the loss of easy value semantics and pointer hops (due to virtual function lookup) are a barrier to the compiler’s optimizer;
                                variants instead of traditional inheritance can work when you have a closed set of types
                            </li></ul>
                        <li><a href="https://www.youtube.com/watch?v=2UmDvg5xv1U">Modern C++ API Design: From Rvalue-References to Type Design</a><comment>//</comment><author>Titus Winters</author></li>
                            <ul><li>
                                Avoid mutable; regular types should be copyable, moveable, default constructible, assignable, comparable, and race free;
                                structs should have no invariants; note reference types like iterators, string_view, and span remove local reasoning
                            </li></ul>
                    </ul>

                    <p class={this.props.nclass}>
                        I really like where the language is going. C++ has always been the “lower-level” language that gave you all the tools you needed but not always the nicest tools. 
                        C++ seems to be going in the direction of making the common case easy while still allowing you to dig deep to get the best performance where needed.
                    </p>
                </contentmain>
                <columnbreak/>
            </contentcontainer>
        );
    }
}
