import './style';
import App from './components/app';

export function routeHtml(route) {
    if (route == homeRoute()) {
        return (
            <span class="icon-html" />
        );
    } else if (route == modernCppRoute()) {
        return (
            <span class="icon-cpp" />
        );
    } else if (route == homelabRoute()) {
        return (
            <span class="icon-settings" />
        );
    } else if (route == monitoringRoute()) {
        return (
            <span class="icon-shell" />
        );
    } else if (route == servicesRoute()) {
        return (
            <span class="icon-yml" />
        );
    } else if (route == travelRoute()) {
        return (
            <span class="icon-image" />
        );
    } else if (route == devRoute() || route == selfHostingRoute() || route == photographyRoute()) {
        return (
            <span class="icon-expanded" />
        );
    }
}
export function routeName(route) {
    if (route == homeRoute()) {
        return "index.html";
    } else if (route == devRoute()) {
        return "development";
    } else if (route == modernCppRoute()) {
        return "modern.cpp";
    } else if (route == selfHostingRoute()) {
        return "self-hosting";
    } else if (route == homelabRoute()) {
        return "homelab.conf";
    } else if (route == monitoringRoute()) {
        return "monitoring.log"
    } else if (route == servicesRoute()) {
        return "services.yml"
    } else if (route == photographyRoute()) {
        return "photography";
    } else if (route == travelRoute()) {
        return "travel.img";
    }
}

export function homeRoute() { return '/'; }
export function devRoute() { return '/development'; }
export function modernCppRoute() { return '/development/moderncpp'; }
export function selfHostingRoute() { return '/self-hosting'; }
export function homelabRoute() { return '/self-hosting/homelab'; }
export function monitoringRoute() { return '/self-hosting/monitoring'; }
export function servicesRoute() { return '/self-hosting/services'; }
export function photographyRoute() { return '/photography'; }
export function travelRoute() { return '/photography/travel'; }

export default App;
