import { Component } from 'preact';
import style from './style';

export default class Homelab extends Component {
    componentDidUpdate() {
        this.props.computeLines();
    }

    render() {
        return(
            <contentcontainer>
                <contentheader>
                    <h1>Homelab</h1>
                    <h2>building a self-hosting infrastructure</h2>
                </contentheader>
                <contentmain>
                    <p class={this.props.nclass}>
                        I was a long time user of free services for things like email, calendar,
                        file storage, etc. Over time I have become increasingly more
                        concerned with privacy and the ownership of my data. With this focus
                        and a history of working with Linux and open source software, I
                        stumbled upon the self-hosting model and immediately knew this was
                        for me.
                    </p>

                    <p class={this.props.nclass}>
                        There are many ways to self-host services. I have settled on a
                        somewhat traditional approach of a large, central server with smaller
                        “off-site” servers for backups and monitoring. Instead of running
                        on bare metal, I host everything with Docker; containerization is
                        definitely the way to go.
                    </p>

                    <p class={this.props.nclass}>
                        I concentrated on building a solid, reliable foundation for my
                        self-hosting journey; this way I could rest assured that I would not
                        need to re-work anything major and not have a sub-component fail
                        while building layer upon layer. One of the biggest benefits of this
                        approach is being able to more easily maintain the system due to
                        being forced to learn and understand all the components, which helps
                        in troubleshooting and fixing issues (they definitely happen).
                    </p>

                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        HARDWARE
                    </p>
                    <p class={this.props.nclass}>
                        The very bottom of this foundation is, of course, hardware.
                    </p>
                    <p class={this.props.nclass}>
                        I built my central server after AMD Ryzen CPUs were out for a while
                        when I got a great deal on a Ryzen 7 1700x. I am using a consumer
                        grade B450 motherboard with 32 GB of ECC RAM (as this will be
                        running <a href="https://en.wikipedia.org/wiki/ZFS">ZFS</a>).
                        I have Ubuntu 18.04 running on an NVME boot drive and 4 4 TB hard
                        drives in RAIDZ10 for media storage.
                    </p>
                    <p class={this.props.nclass}>
                        For backups I have 2 servers in different geographic locations
                        that were hobbled together by scrounging up parts. Both are running
                        Ubuntu 18.04 with their data disks managed by <a href="https://en.wikipedia.org/wiki/Mdadm">mdadm</a>. 
                        See the backup section below for details about how I utilize these
                        servers.
                    </p>
                    <p class={this.props.nclass}>
                        I also have a VPS with <a href="https://www.linode.com/products/shared/">Linode</a> ($5/mo. node)
                        running Ubuntu 20.04 that I use for monitoring the other servers.
                        See <a href="/self-hosting/monitoring">Monitoring.log</a> for details about how I use this.
                    </p>
                    <p class={this.props.nclass}>
                        The technical stuff is the fun part; naming is the hard part. As
                        this is a personal project (no one else needs to understand the
                        names), there is freedom to be creative with naming, instead of some
                        cold, generated naming like <hname>regionAcronym1234</hname>. My hostnames are
                        based on the Dune universe, as I’m a huge fan of the books; for
                        example: <hname>fremen</hname>, <hname>mentat</hname>, <hname>ghola</hname>, <hname>tprobe</hname>, etc.
                    </p>

                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        CONFIGURATION
                    </p>
                    <p class={this.props.nclass}>
                        If configurations are not documented and stored off-device, they are not
                        reproducible. Infrastructure as Code is the goal. I keep all my
                        configs and initial configuration (packages to install, access, etc.)
                        in a private git repository in GitLab; I use git-crypt here to
                        protect secrets. This repository needs to exist completely external
                        to my devices, in case of some catastrophe; thus, this is in GitLab
                        instead of some self-hosted service.
                    </p>
                    <p class={this.props.nclass}>
                        I maintain a set of bash scripts that will run setup scripts and
                        copy config files based on the host. Any configuration change I make,
                        I replicate in this repository. This should all probably be replaced
                        with <a href="https://www.ansible.com/">Ansible</a>,
                        but I haven’t gotten around to learning it yet.
                    </p>

                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        DOCKER
                    </p>
                    <p class={this.props.nclass}>
                        Containerization is my preferred way to manage many services. It keeps one service
                        from affecting another, makes it easier to document configuration,
                        and allows moving services to different hosts pretty simply. I use
                        Docker for containerization and running of my services. I run Docker
                        on my central server and my VPS.
                    </p>
                    <p class={this.props.nclass}>
                        It has been a journey to learn Docker. I started out learning it
                        when I first decided to start self-hosting with
                        exploring <a href="https://www.home-assistant.io/">Home Assistant</a> to
                        automate some smart lights. Networking, volumes, data permissions
                        were some <accentitalic>fun</accentitalic> things to understand how they work with Docker.
                    </p>
                    <p class={this.props.nclass}>
                        I landed on a strategy of using <a href="https://docs.docker.com/compose/">Docker
                        Compose</a> with a docker-compose.yml file, which contains the
                        configuration to run all the containers needed, for each top-level
                        service/application/website
                        like <a href="https://www.home-assistant.io/">Home Assistant</a>, <a href="https://nextcloud.com/">Nextcloud</a>,
                        or <a href="https://matrix.org/docs/guides/installing-synapse">Matrix/Synapse</a>.
                        I keep these docker compose files in my configuration repository.
                        Here is an example of how I use docker compose file for self-hosting
                        Matrix/Synapse: <a href="https://github.com/wkelton/matrix-docker-compose/blob/master/docker-compose.yml">docker-compose.yml</a>.
                    </p>

                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        BACKUP
                    </p>
                    <p class={this.props.nclass}>
                        There are many approaches when it comes to backing up data. I wanted
                        something automatable and easy to understand. And as I controlled
                        both the source (central server) and destination
                        (2 backup servers), <a href="https://en.wikipedia.org/wiki/Rsync">rsync</a> would 
                        do the trick. However, backing up my container volumes wouldn’t
                        be that simple. Moreover, I wanted to receive alerts for failures.
                    </p>
                    <p class={this.props.nclass}>
                        This lead me to developing my own Python solution: <a
                        href="https://github.com/wkelton/abackup">abackup</a>. <accentitalic>abackup</accentitalic> is
                        a collection of tools for backups (for docker), monitoring
                        storage health, and syncing data between hosts. It also supports
                        providing notifications through Slack.
                    </p>
                    <p class={this.props.nclass}>
                        My backup strategy is automated and is as follows:
                    </p>
                    <ul class={this.props.nclass}>
                        <li><accentitalic>abackup</accentitalic> makes a backup tar file of each of my docker volumes</li>
                        <li><accentitalic>abackup</accentitalic> syncs these backups, as well as other desired data, to
                        each of my backup servers</li>
                        <li><accentitalic>abackup</accentitalic> checks drive status on each of my servers</li>
                    </ul>
                    <p class={this.props.nclass}>
                        If any errors occur, I receive an alert in Slack. And if any
                        silent error occurs, like the automation itself failing, I will
                        receive an alert from <a href="https://healthchecks.io">Healthchecks</a>.
                        See <a href="/self-hosting/monitoring">Monitoring.log</a> for
                        more details about how I monitor my systems.
                    </p>

                    <p class={[style.pheader, this.props.nclass].join(" ")}>
                        REVERSE PROXY
                    </p>
                    <p class={this.props.nclass}>
                        A foundational piece of a self-hosting infrastructure is a <a
                        href="https://en.wikipedia.org/wiki/Reverse_proxy">reverse
                        proxy</a>; as typically with self-hosting, there are many
                        public/private facing services running on the same host. This allows
                        you to do many interesting things, like selectively keeping services
                        internal and accessing services by different domain names all on the
                        common http/s ports.
                    </p>
                    <p class={this.props.nclass}>
                        I use a specific workflow built on NGINX, <a
                        href="https://github.com/nginx-proxy/nginx-proxy">nginx-proxy</a>,
                        on my central server because of its greater flexibility and
                        configurability. I also use <a
                        href="https://traefik.io/">Traefik</a> on
                        my VPS because of its much simpler ease of use. <a
                        href="https://caddyserver.com/">Caddy</a> is
                        another popular choice. I run my reverse proxies in Docker
                        containers just like my services for the same reasons.
                    </p>
                    <p class={this.props.nclass}>
                        I host many services publicly and internally. I access all these
                        services, even internal ones, with a domain name over HTTPS. This can
                        actually be a little complicated depending on how you have your
                        system setup. Both the reverse proxies I use support <a
                        href="https://letsencrypt.org/">Let’s Encrypt</a> for
                        automated TLS certificate issuance, which I have configured. I use <a
                        href="https://www.namecheap.com/">Namecheap</a> for
                        domain registration and DNS. And as I don’t have a static public
                        IP, I have <a href="https://ddclient.net/">ddclient</a> configured
                        to automate updating DNS with Namecheap when my public IP
                        changes. Namecheap has great Dynamic DNS support.
                    </p>

                    <p class={[style.pheader, this.props.nclass].join(" ")}/>
                    <p class={this.props.nclass}>
                        With this foundation, I feel secured to build services on top.
                        This seems like a lot of work, and it is, but it provides great piece of mind.
                        See <a href="/self-hosting/monitoring">Monitoring.log</a> and <a href="/self-hosting/services">Services.yml</a> for
                        details on my homelab.
                    </p>
                </contentmain>
                <columnbreak/>
            </contentcontainer>
        );
    }
}