import { Component } from 'preact';
import { Link } from 'preact-router/match';
import style from './style';
import { routeHtml, routeName, homeRoute, devRoute, modernCppRoute, selfHostingRoute, homelabRoute, monitoringRoute, servicesRoute, photographyRoute, travelRoute } from '../..';

export default class Navigation extends Component {
    render() {
        return (
            <navigation class={style.navigation}>
                <header><small>EXPLORER</small></header>
                <Link activeClassName={style.active} class={style.navLink} href={homeRoute()}>{routeHtml(homeRoute())}{routeName(homeRoute())}</Link>
                <Link activeClassName={style.active} class={style.navLink} href={devRoute()}>{routeHtml(devRoute())}{routeName(devRoute())}</Link>
                <Link activeClassName={style.active} class={[style.navLink, style.sub1].join(" ")} href={modernCppRoute()}>{routeHtml(modernCppRoute())}{routeName(modernCppRoute())}</Link>
                <Link activeClassName={style.active} class={style.navLink} href={selfHostingRoute()}>{routeHtml(selfHostingRoute())}{routeName(selfHostingRoute())}</Link>
                <Link activeClassName={style.active} class={[style.navLink, style.sub1].join(" ")} href={homelabRoute()}>{routeHtml(homelabRoute())}{routeName(homelabRoute())}</Link>
                <Link activeClassName={style.active} class={[style.navLink, style.sub1].join(" ")} href={monitoringRoute()}>{routeHtml(monitoringRoute())}{routeName(monitoringRoute())}</Link>
                <Link activeClassName={style.active} class={[style.navLink, style.sub1].join(" ")} href={servicesRoute()}>{routeHtml(servicesRoute())}{routeName(servicesRoute())}</Link>
                <Link activeClassName={style.active} class={style.navLink} href={photographyRoute()}>{routeHtml(photographyRoute())}{routeName(photographyRoute())}</Link>
                <Link activeClassName={style.active} class={[style.navLink, style.sub1].join(" ")} href={travelRoute()}>{routeHtml(travelRoute())}{routeName(travelRoute())}</Link>
            </navigation>
        );
    }
}
