import { h, Component } from 'preact';
import { Router } from 'preact-router';

import style from './style';

import Redirect from '../../routes/redirect';
import Home from '../../routes/home';
import ModernCpp from '../../routes/development/moderncpp';
import Homelab from '../../routes/self-hosting/homelab';
import Monitoring from '../../routes/self-hosting/monitoring';
import Services from '../../routes/self-hosting/services';
import Travel from '../../routes/photography/travel';
import { routeHtml, routeName, homeRoute, devRoute, modernCppRoute, selfHostingRoute, homelabRoute, monitoringRoute, servicesRoute, photographyRoute, travelRoute } from '../..';

export default class Content extends Component {
  constructor() {
    super();
    this.state = { url: '/', fontsLoaded: false, delayedComputeLines: false };
  }

  handleRoute = (e) => {
    this.setState({ url: e.url });
  }

  allowLines = () => {
    return window.innerWidth >= 600 || !this.props.navVisible;
  }

  computeLines = () => {
    if (!this.state.fontsLoaded) {
      if (!this.state.delayedComputeLines) {
        this.setState({ delayedComputeLines: true });
      }
      return;
    }

    // remove any previous numbering
    var paras = document.getElementsByClassName(style.contentLines);
    while (paras[0]) {
      paras[0].parentNode.removeChild(paras[0]);
    }

    if (!this.allowLines()) {
      return;
    }

    // calculate zoom 
    var screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;

    // compute a reference height
    var paragraphs = document.getElementsByTagName("P");
    var el = paragraphs.item(0);
    el.insertAdjacentHTML('beforebegin', "<p style='margin: 0px; padding=0px;'>Dummy</p>");
    el = document.getElementsByTagName("P").item(0);
    var refHeight = el.clientHeight;
    el.parentNode.removeChild(el);

    // compute scaled reference height, subtracting one as a fudge factor for rounding
    // that the browser may have done to report clientHeight as int
    var refScaledHeight = Math.floor((refHeight - 0.5) * screenCssPixelRatio);

    var cnt = 1;
    var previousPos = false;

    function addLineNumber(element, top, doPreviousLine = false) {
      function insertHTMLLine(styleTop) {
        element.insertAdjacentHTML('beforebegin', `<p class='${style.contentLines}' style='top: ${styleTop}px;'>${cnt++}</p>`);
      }
      if (doPreviousLine) {
        insertHTMLLine(previousPos + (top - previousPos) / 2);
      }
      insertHTMLLine(top);
      previousPos = top;
    }

    function addLineNumbersForText(element, doPreviousLine = false, topElement = null) {
      // get the number of lines in the paragraph
      var posTop = element.offsetTop;
      var paragraphHeight = element.clientHeight;
      // calculate scaled paragraph height, adding one as a fudge factor for rounding
      // that the browser may have done to report clientHeight as int
      var paragraphScaledHeight = Math.floor((paragraphHeight + 0.5) * screenCssPixelRatio);
      var lines = Math.floor(paragraphScaledHeight / refScaledHeight);
      var lineHeight = paragraphHeight / lines;

      // loop through lines, stopping when close to the full height within 0.01
      for (var i = posTop; i < posTop + paragraphHeight - 0.01; i += lineHeight) {
        addLineNumber(topElement ? topElement : element, i, doPreviousLine ? i == posTop : false);
      }
    }

    // loop through everything
    var elements = document.getElementsByClassName(style.numberable);
    Array.prototype.forEach.call(elements, function (element) {
      if (element.tagName == "P") {
        addLineNumbersForText(element, previousPos !== false);
      } else if (element.tagName == "UL") {
        var doPreviousLine = previousPos !== false;
        var listElements = element.getElementsByTagName("li");
        Array.prototype.forEach.call(listElements, function (listElement) {
          addLineNumbersForText(listElement, doPreviousLine, element);
          doPreviousLine = false;
        });
      } else if (element.tagName == "DIV" || element.tagName == "CODEBLOCK") {
        var lineElements = element.getElementsByClassName("codeline");
        var doPreviousLine = previousPos !== false;
        Array.prototype.forEach.call(lineElements, function (lineElement) {
          addLineNumbersForText(lineElement, doPreviousLine, element);
          doPreviousLine = false;
        });
      } else if (element.tagName == "IMG" && element.complete) {
        var doPreviousLine = previousPos !== false;
        var numLines = Math.ceil(element.clientHeight / refHeight);
        var height = element.clientHeight / numLines;
        for (var i = -1; i < numLines + 1; ++i) {
          addLineNumber(element, element.offsetTop + i * height, doPreviousLine);
          doPreviousLine = false;
        }
      }
    });
  }

  fontsLoaded = () => {
    if (this.state.delayedComputeLines) {
      this.setState({ fontsLoaded: true, delayedComputeLines: false });
    } else {
      this.setState({ fontsLoaded: true });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.computeLines);
    document.fonts.onloadingdone = this.fontsLoaded;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.computeLines);
  }

  render() {
    return (
      <content class={style.content} path={homeRoute()}>
        <tab>
          {routeHtml(this.state.url)}{routeName(this.state.url)}
        </tab>
        <empty />
        <main>
          <Router onChange={this.handleRoute}>
            <Home path={homeRoute()} nclass={style.numberable} computeLines={this.computeLines} />
            <Redirect path={devRoute()} to={modernCppRoute()} />
            <ModernCpp path={modernCppRoute()} nclass={style.numberable} computeLines={this.computeLines} />
            <Redirect path={selfHostingRoute()} to={homelabRoute()} />
            <Homelab path={homelabRoute()} nclass={style.numberable} computeLines={this.computeLines} />
            <Monitoring path={monitoringRoute()} nclass={style.numberable} computeLines={this.computeLines} />
            <Services path={servicesRoute()} nclass={style.numberable} computeLines={this.computeLines} />
            <Redirect path={photographyRoute()} to={travelRoute()} />
            <Travel path={travelRoute()} nclass={style.numberable} computeLines={this.computeLines} />
            <Redirect default to={homeRoute()} />
          </Router>
        </main >
      </content >
    );
  }
}
