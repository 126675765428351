import { Component } from 'preact';
import Navbar from './bar';
import Content from './content';

if (module.hot) {
    require('preact/debug');
}

export default class App extends Component {
    constructor() {
        super();
        this.state = { navVisible: false };
    }

    handleNavVisibilityChanged = (navVisible) => {
        this.setState({ navVisible: navVisible });
    }

    render() {
        return (
            <div id="app">
                <Navbar onNavVisiblityChanged={this.handleNavVisibilityChanged} />
                <Content navVisible={this.state.navVisible} />
            </div>
        );
    }
}
