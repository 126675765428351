import { Component } from 'preact';
import Navigation from '../navigation'
import style from './style';

export default class Navbar extends Component {
    constructor() {
        super();
        this.state = { navVisible: true };
    }

    render() {
        return (
            <div class={style.navbar} id="myNavbar">
                <bar class={style.bar}>
                    <div class={style.clickable} onClick={this.handleBarClick}>&lt;/&gt;</div>
                </bar>
                <div class={style.navwrapper} id="navwrapper">
                    <Navigation />
                </div>
            </div>
        );
    }
    
    handleBarClick = () => {
        var navbar = document.getElementById("navwrapper");
        var isVisible = window.getComputedStyle(navbar).getPropertyValue('display') !== 'none';
        this.setState(() => ({ navVisible: !isVisible }));
        isVisible ? navbar.style.display = 'none' : navbar.style.display = 'unset';
        this.props.onNavVisiblityChanged(!isVisible);
    }
}
